// action - state management
import * as actionTypes from './actions';

const initialState = {
    balanceType: 'zar'
};

// ==============================|| WALLET BALANCE TYPE REDUCER ||============================== //

const balanceTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.WALLET_BALANCE_TYPE:
            return {
                ...state,
                balanceType: action.balanceType ? action.balanceType : initialState.balanceType
            };
        default:
            return state;
    }
};

export default balanceTypeReducer;
