// action - state management
import * as actionTypes from './actions';

const initialState = {
    walletZARBalance: 0,
    walletZIMBalance: 0,
    walletUSDBalance: 0
};

// ==============================|| WALLET REDUCER ||============================== //

const walletReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_WALLET_BALANCE:
            return {
                ...state,
                walletZARBalance: action.walletZARBalance ? action.walletZARBalance : initialState.walletZARBalance,
                walletZIMBalance: action.walletZIMBalance ? action.walletZIMBalance : initialState.walletZIMBalance,
                walletUSDBalance: action.walletUSDBalance ? action.walletUSDBalance : initialState.walletUSDBalance
            };
        default:
            return state;
    }
};

export default walletReducer;
