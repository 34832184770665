import { lazy } from 'react';

// project imports
import Loadable from 'ui/ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const PurchaseSimpleVoucher = Loadable(lazy(() => import('pages/return-transactions/PurchaseSimpleVoucher')));
const PurchaseOTTVoucher = Loadable(lazy(() => import('pages/return-transactions/PurchaseOTTVoucher')));
const TopUpWalletVerify = Loadable(lazy(() => import('pages/return-transactions/TopUpWalletVerify')));
const CheckPaynowPaymentStatus = Loadable(lazy(() => import('pages/return-transactions/CheckPaynowPaymentStatus')));

// forgot password
const AuthForgotPassword = Loadable(lazy(() => import('pages/authentication/authentication/ForgotPassword')));
const AuthEmailCodeVerification = Loadable(lazy(() => import('pages/authentication/authentication/EmailCodeVerification')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const OtherRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/purchase-simple-voucher',
            element: <PurchaseSimpleVoucher />
        },
        {
            path: '/purchase-ott-voucher',
            element: <PurchaseOTTVoucher />
        },
        {
            path: '/top-up-wallet-verify',
            element: <TopUpWalletVerify />
        },
        {
            path: '/pay-now/check-transaction',
            element: <CheckPaynowPaymentStatus />
        },
        {
            path: '/forgot-password',
            element: <AuthForgotPassword />
        },
        {
            path: '/email-verification',
            element: <AuthEmailCodeVerification />
        }
    ]
};

export default OtherRoutes;
