import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ReactComponent as CompanyIcon } from './assets/images/simplexsolutionslogo.svg';


import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import Locales from 'ui/ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui/ui-component/extended/Snackbar';

import CookieConsent from 'react-cookie-consent';



// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <Locales>
                    <NavigationScroll>
                        <>
                            <Routes />
                            <Snackbar />
                            <CookieConsent
                                location="bottom"
                                buttonText="OK"
                                cookieName="cookieConsent"
                                style={{ background: '#2B373B' }}
                                buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                                expires={365}
                            >
                                We use cookies to provide our services and enhance your experience. By using this website, you agree with
                                this.
                                {/* <span style={{ fontSize: '10px' }}>This bit of text is smaller :O</span> */}
                            </CookieConsent>
                            <WhatsAppWidget
                                CompanyIcon={CompanyIcon}
                                phoneNumber={process.env.REACT_APP_WHATSAPP_NUMBER}
                                companyName="Simplex Solutions"
                            />
                        </>
                    </NavigationScroll>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
