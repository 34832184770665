const config = {
    // basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/dashboard',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    outlinedFilled: true,
    theme: 'light', // light, dark
    presetColor: 'theme3', // default, theme1, theme2, theme3, theme4, theme5, theme6
    i18n: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    rtlLayout: false,
    southAfricanCurrency: 'ZAR',
    southAfricanCurrencySymbol: 'R',
    zimbabweanCurrency: 'ZWG',
    unitedStatesCurrency: 'USD',
    jwt: {
        secret: 'SECRET-KEY',
        timeout: '1 days'
    },
    sessionHours: 1,
    firebase: {
        apiKey: 'AIzaSyBernKzdSojh_vWXBHt0aRhf5SC9VLChbM',
        authDomain: 'berry-material-react.firebaseapp.com',
        projectId: 'berry-material-react',
        storageBucket: 'berry-material-react.appspot.com',
        messagingSenderId: '901111229354',
        appId: '1:901111229354:web:a5ae5aa95486297d69d9d3',
        measurementId: 'G-MGJHSL8XW3'
    },
    auth0: {
        client_id: '7T4IlWis4DKHSbG8JAye4Ipk0rvXkH9V',
        domain: 'dev-w0-vxep3.us.auth0.com'
    },
    airtimeAmounts: [10, 20, 30, 50, 100, 200, 500, 1000], // available options for airtime to buy
    zesaAmounts: [10, 20, 50, 100, 200, 500, 1000], // available options for zesa tokens to buy
    voucherAmounts: [10, 20, 30, 50, 100, 200, 500, 1000], // available options for vouchers to buy
    dstvOptions: [
        { label: 'DSTV', value: 'DSTV' },
        { label: 'GOTV', value: 'GOTV' },
        { label: 'Box Office', value: 'BO' }
    ],
    econetUSDPackages: [
        { label: 'Data', value: 'DATA' },
        { label: 'SMS', value: 'SMS' },
        { label: 'Voice', value: 'VOICE' },
        { label: 'USD Value', value: 'USD VALUE' },
        { label: 'WhatsApp Bundles', value: 'WHATSAPP_BUNDLES' }
    ],
    econetPackages: [
        { label: 'Econet Data', value: 'Econet Data' },
        { label: 'Econet Text', value: 'Econet Text' },
        { label: 'Econet Twitter', value: 'Econet Twitter' },
        { label: 'Econet Instagram', value: 'Econet Instagram' },
        { label: 'Econet Facebook', value: 'Econet Facebook' },
        { label: 'Econet WhatsApp', value: 'Econet WhatsApp' }
    ],
    networksAvailableSA: [
        { label: 'Vodacom', value: 'Vodacom' },
        { label: 'MTN', value: 'MTN' },
        { label: '8ta', value: '8ta' },
        { label: 'CellC', value: 'CellC' }
    ],
    networksAvailableZIM: [
        { label: 'Econet', value: 'econet' },
        { label: 'Netone', value: 'netone' },
        { label: 'Telecel', value: 'telecel' }
    ],
    availablePackages: [
        { label: 'Data', value: 'Data' },
        { label: 'SMS', value: 'SMS' }
        // { label: 'Other', value: 'Other' },
    ],
    HRBundleTypes: [
        {
            label: 'Airtime',
            value: 'voice'
        },
        {
            label: 'Data',
            value: 'data'
        },
        {
            label: 'SMS',
            value: 'sms'
        },
        {
            label: 'Whatsapp',
            value: 'whatsapp'
        },
        {
            label: 'Facebook',
            value: 'facebook'
        },
        {
            label: 'Instagram',
            value: 'instagram'
        },
        {
            label: 'Twitter',
            value: 'twitter'
        }
        // {
        //     label: 'OneFi',
        //     value: 'onefi'
        // },
        // {
        //     label: 'OneFusion',
        //     value: 'onefusion'
        // },
        // {
        //     label: 'EVD',
        //     value: 'evd'
        // }
    ],
    NetoneBundleTypes: [
        {
            label: 'Airtime',
            value: 'voice'
        },
        {
            label: 'Data',
            value: 'data'
        },
        {
            label: 'SMS',
            value: 'sms'
        },
        {
            label: 'OneFi',
            value: 'onefi'
        }
    ],
    telOneTypeOptions: [
        { label: 'ADSL', value: 'ADSL' },
        { label: 'BILL', value: 'BILL' },
        { label: 'EVD', value: 'EVD' },
        { label: 'VOIP', value: 'VOIP' }
    ],
    countryCodes: [
        { code: '263', label: 'Zimbabwe (+263)' },
        { code: '27', label: 'South Africa (+27)' }
    ]
};

export default config;
