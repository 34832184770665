import { useDispatch } from 'react-redux';
import { SNACKBAR_OPEN } from 'store/actions';

const { useEffect, useState } = require('react');

const useNetworkStatus = () => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(navigator.onLine);

    useEffect(() => {
        const setOnline = () => {
            setStatus(true);
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'Your internet connection has been restored',
                variant: 'alert',
                alertSeverity: 'info',
                close: false
            });
        };

        const setOffline = () => {
            setStatus(false);
            dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: 'No Internet Connection',
                variant: 'alert',
                alertSeverity: 'error',
                close: false
            });
        };

        window.addEventListener('online', setOnline);
        window.addEventListener('offline', setOffline);
        return () => {
            window.addEventListener('online', setOnline);
            window.addEventListener('offline', setOffline);
        };
    }, [dispatch]);

    return status;
};

export default useNetworkStatus;
