import { createStore } from 'redux';
import { persistStore } from 'redux-persist';
import reducer from './reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

// ==============================|| REDUX - MAIN STORE ||============================== //

const env = process.env.REACT_APP_ENV;

const store = createStore(reducer, env === 'DEV' ? composeWithDevTools() : {});
const persister = persistStore(store);

export { store, persister };
