// action - auth reducer
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const UPDATE_EMAIL_VERIFICATION_STATUS = 'UPDATE_EMAIL_VERIFICATION_STATUS';
export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';

// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const MENU_TYPE = '@customization/MENU_TYPE';
export const PRESET_COLORS = '@customization/PRESET_COLORS';
export const THEME_LOCALE = '@customization/THEME_LOCALE';
export const THEME_RTL = '@customization/THEME_RTL';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_OUTLINED_FILLED = '@customization/SET_OUTLINED_FILLED';

// action - snackbar
export const SNACKBAR_OPEN = '@snackbar/SNACKBAR_OPEN';

// action - settings
export const UPDATE_SETTINGS = '@settings/UPDATE_SETTINGS';

// action - biller payNowCheckout
export const LOAD_CHECKOUT_BILLER = '@payNowCheckout/LOAD_CHECKOUT_BILLER';
export const SELECT_CHECKOUT_PRODUCT = '@payNowCheckout/SELECT_CHECKOUT_PRODUCT';
export const CLEAR_CHECKOUT_DATA = '@payNowCheckout/CLEAR_CHECKOUT_DATA';
export const UPDATE_CUSTOMER_DATA = '@payNowCheckout/UPDATE_CUSTOMER_DATA';
export const RESET_CUSTOMER_DATA = '@payNowCheckout/RESET_CUSTOMER_DATA';

// action - wallet
export const UPDATE_WALLET_BALANCE = '@settings/UPDATE_WALLET_BALANCE';
export const WALLET_BALANCE_TYPE = '@settings/WALLET_BALANCE_TYPE';

// department rates
export const PATCH_BILL_PAY_RATES = '@settings/PATCH_BILL_PAY_RATES';

// paystack deposit
export const UPDATE_PAYSTACK_SETTINGS = '@settings/UPDATE_PAYSTACK_SETTINGS';

// action - cart
export const ADD_PRODUCTS = '@cart/ADD_PRODUCTS';
export const REMOVE_PRODUCT = '@cart/REMOVE_PRODUCT';
export const UPDATE_QUANTITY = '@cart/UPDATE_QUANTITY';
export const NEXT_STEP = '@cart/NEXT_STEP';
export const BACK_STEP = '@cart/BACK_STEP';
export const SET_STEP = '@cart/SET_STEP';
export const SET_BILLING_ADDRESS = '@cart/SET_BILLING_ADDRESS';
export const SET_DISCOUNT = '@cart/SET_DISCOUNT';
export const SET_SHIPPING_CHARGE = '@cart/SET_SHIPPING_CHARGE';
export const SET_PAYMENT_METHOD = '@cart/SET_PAYMENT_METHOD';
export const SET_PAYMENT_CARD = '@cart/SET_PAYMENT_CARD';
export const RESET_CART = '@cart/RESET_CART';

// action - kanban
export const ADD_COLUMN = '@kanban/ADD_COLUMN';
export const EDIT_COLUMN = '@kanban/EDIT_COLUMN';
export const DELETE_COLUMN = '@kanban/DELETE_COLUMN';
export const UPDATE_COLUMN_ORDER = '@kanban/UPDATE_COLUMN_ORDER';

export const ADD_ITEM = '@kanban/ADD_ITEM';
export const EDIT_ITEM = '@kanban/EDIT_ITEM';
export const DELETE_ITEM = '@kanban/DELETE_ITEM';
export const UPDATE_COLUMN_ITEM_ORDER = '@kanban/UPDATE_COLUMN_ITEM_ORDER';
export const SELECT_ITEM = '@kanban/SELECT_ITEM';
export const ADD_ITEM_COMMENT = '@kanban/ADD_ITEM_COMMENT';

export const UPDATE_STORY_ORDER = '@kanban/UPDATE_STORY_ORDER';
export const UPDATE_STORY_ITEM_ORDER = '@kanban/UPDATE_STORY_ITEM_ORDER';
export const DELETE_STORY = '@kanban/DELETE_STORY';
export const ADD_STORY = '@kanban/ADD_STORY';
export const EDIT_STORY = '@kanban/EDIT_STORY';
export const ADD_STORY_COMMENT = '@kanban/ADD_STORY_COMMENT';

// action - popup
export const OPEN_POPUP = '@popup/OPEN_POPUP';
export const CLOSE_POPUP = '@popup/CLOSE_POPUP';


// action - user profile
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';

// action - Simple Voucher
export const UPDATE_SIMPLE_VOUCHER_FEES = '@settings/UPDATE_SIMPLE_VOUCHER_FEES';