// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconWallet } from '@tabler/icons';

// constant
const icons = {
    IconWallet
};

const wallet = {
    id: 'wallet',
    type: 'group',
    title: <FormattedMessage id="wallet" />,
    children: [
        {
            id: 'wallet-top-up',
            title: <FormattedMessage id="wallet-top-up" />,
            type: 'item',
            url: '/top-up-wallet-options',
            icon: icons.IconWallet,
            breadcrumbs: true
        }
    ]
};

export default wallet;
