// action - state management
import * as actionTypes from './actions';

const initialState = {
    emailAddress: '',
    displayName: '' || sessionStorage.getItem('fullName'),
    isEmailVerified: null,
    isLoggedIn: null,
    expires: null
};

// ==============================|| AUTH REDUCER ||============================== //

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN:
            return {
                ...state,
                emailAddress: action.emailAddress ? action.emailAddress : initialState.emailAddress,
                displayName: action.displayName ? action.displayName : initialState.displayName,
                isEmailVerified: action.isEmailVerified ? action.isEmailVerified : initialState.isEmailVerified,
                isLoggedIn: action.isLoggedIn ? action.isLoggedIn : initialState.isLoggedIn,
                expires: action.expires ? action.expires : initialState.expires
            };
        case actionTypes.UPDATE_EMAIL_VERIFICATION_STATUS:
            return {
                ...state,
                isEmailVerified: action.isEmailVerified ? action.isEmailVerified : initialState.isEmailVerified
            };
        case actionTypes.SET_IS_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.isLoggedIn ? action.isLoggedIn : initialState.isLoggedIn
            };
        case actionTypes.LOGOUT:
            return {
                ...state,
                emailAddress: '',
                displayName: '',
                isEmailVerified: null,
                isLoggedIn: null,
                expires: null
            };
        default:
            return state;
    }
};

export default authReducer;
