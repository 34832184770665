// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconTable, IconPlayerRecord, IconSitemap } from '@tabler/icons';

// constant
const icons = {
    IconTable,
    IconPlayerRecord,
    IconSitemap
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const transactions = {
    id: 'transactions',
    type: 'group',
    title: <FormattedMessage id="transactions" />,
    children: [
        {
            id: 'transaction',
            title: <FormattedMessage id="transaction" />,
            type: 'collapse',
            icon: icons.IconTable,
            children: [
                {
                    id: 'airtime-transactions',
                    title: <FormattedMessage id="airtime-transactions" />,
                    type: 'item',
                    url: '/transactions/airtime',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'usd-airtime-transactions',
                    title: <FormattedMessage id="usd-airtime-transactions" />,
                    type: 'item',
                    url: '/transactions/usd-airtime',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'bundles-transactions',
                    title: <FormattedMessage id="bundles-transactions" />,
                    type: 'item',
                    url: '/transactions/bundles',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'usd-bundle-transactions',
                    title: <FormattedMessage id="usd-bundle-transactions" />,
                    type: 'item',
                    url: '/transactions/usd-bundles',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'pinless-airtime',
                    title: <FormattedMessage id="pinless-airtime" />,
                    type: 'item',
                    url: '/transactions/pinless-airtime',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'zesa-tokens-transactions',
                    title: <FormattedMessage id="zesa-tokens-transactions" />,
                    type: 'item',
                    url: '/transactions/zesa-tokens',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'dstv-payments',
                    title: <FormattedMessage id="dstv-payments" />,
                    type: 'item',
                    url: '/transactions/multichoice',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'nyaradzo-transactions',
                    title: <FormattedMessage id="nyaradzo-transactions" />,
                    type: 'item',
                    url: '/transactions/nyaradzo',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'telone-transactions',
                    title: <FormattedMessage id="telone-transactions" />,
                    type: 'item',
                    url: '/transactions/telone',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'eskom-electricity',
                    title: <FormattedMessage id="eskom-electricity" />,
                    type: 'item',
                    url: '/transactions/eskom-electricity',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'simple-payment-transactions',
                    title: <FormattedMessage id="simple-payment-transactions" />,
                    type: 'item',
                    url: '/transactions/payments',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'biller-payment-transactions',
                    title: <FormattedMessage id="biller-payment-transactions" />,
                    type: 'item',
                    url: '/transactions/billers',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default transactions;
