// action - state management
import * as actionTypes from './actions';

const initialState = {
    open: false,
    title: '',
    content: '',
    expires: '',
    public: 0
};

// ==============================|| POPUP REDUCER ||============================== //

const popupReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_POPUP:
            return {
                ...state,
                open: action.open ? action.open : initialState.open,
                title: action.title ? action.title : initialState.title,
                content: action.content ? action.content : initialState.content,
                expires: action.expires ? action.expires : initialState.expires,
                public: action.public ? action.public : initialState.public
            };
        case actionTypes.CLOSE_POPUP:
            return {
                ...state,
                open: false,
                title: '',
                content: '',
                expires: '',
                public: 0
            };
        default:
            return state;
    }
};

export default popupReducer;
