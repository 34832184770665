import * as actionTypes from './actions';

const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    // Add any other relevant user profile fields here
};

// ==============================|| USER PROFILE REDUCER ||============================== //

const userProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_PROFILE:
            return {
                ...state,
                email: action.payload.email ? action.payload.email : initialState.email,
                first_name: action.payload.first_name ? action.payload.first_name : initialState.first_name,
                last_name: action.payload.last_name ? action.payload.last_name : initialState.last_name,
                phone_number: action.payload.phone_number ? action.payload.phone_number : initialState.phone_number,
                // Spread in any additional profile fields from action.payload
                ...action.payload
            };
        case actionTypes.CLEAR_USER_PROFILE:
            return initialState;
        default:
            return state;
    }
};

export default userProfileReducer;
