// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconPlayerRecord, IconTicket } from '@tabler/icons';

// constant
const icons = {
    IconPlayerRecord,
    IconTicket
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const recharge = {
    id: 'vouchers',
    type: 'group',
    title: <FormattedMessage id="vouchers" />,
    children: [
        {
            id: 'vouchers',
            title: <FormattedMessage id="vouchers" />,
            type: 'collapse',
            icon: icons.IconTicket,
            children: [
                {
                    id: 'simple-vouchers',
                    title: <FormattedMessage id="simple-vouchers" />,
                    type: 'item',
                    url: '/simple-vouchers',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'ott-vouchers',
                    title: <FormattedMessage id="ott-vouchers" />,
                    type: 'item',
                    url: '/ott-vouchers',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                },
                {
                    id: 'scode-vouchers',
                    title: <FormattedMessage id="scode-vouchers" />,
                    type: 'item',
                    url: '/scode-vouchers',
                    icon: icons.IconPlayerRecord,
                    breadcrumbs: true
                }
            ]
        }
    ]
};

export default recharge;
