import dashboard from './dashboard';
// import zimServices from './zim-services';
// import saServices from './sa-services';
import transactions from './transactions';
import vouchers from './vouchers';
import wallet from './wallet';
import countryServices from './country-services';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, wallet, countryServices , vouchers, transactions]
};

export default menuItems;
