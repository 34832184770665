import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase, Grid } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import Chip from 'ui/ui-component/extended/Chip';

// assets
import { IconMenu2 } from '@tabler/icons';
import { useSelector } from 'react-redux';
import config from 'config';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();
    const walletZARBalance = useSelector((state) => state.wallet.walletZARBalance);
    const walletZIMBalance = useSelector((state) => state.wallet.walletZIMBalance);
    const walletUSDBalance = useSelector((state) => state.wallet.walletUSDBalance);
    const balance = useSelector((state) => state.balance);

    return (
        <>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                            color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} />

            {/* live customization & localization */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

            {/* notification & profile */}
            {/* <NotificationSection /> */}
            <Box
                sx={{
                    mx: 2,
                    [theme.breakpoints.down('md')]: {
                        mx: 1
                    }
                }}
            >
                {/* <Grid item>
                    <Stack direction="row">
                        <AnimateButton>
                            <Button
                                component={Link}
                                to="/buy-simple-voucher"
                                variant="contained"
                                color="warning"
                                sx={{ boxShadow: 'none' }}
                            >
                                Balance : $
                            </Button>
                        </AnimateButton>
                    </Stack>
                </Grid> */}
                <Grid item>
                    {balance.balanceType === config.southAfricanCurrency.toLocaleLowerCase() && <Chip label={`${config.southAfricanCurrencySymbol} ${walletZARBalance.toFixed(2)}`} />}
                    {balance.balanceType === config.unitedStatesCurrency.toLocaleLowerCase() && <Chip label={`${config.unitedStatesCurrency} $ ${walletUSDBalance.toFixed(2)}`} />}
                    {balance.balanceType === config.zimbabweanCurrency.toLocaleLowerCase () && <Chip label={`${config.zimbabweanCurrency} $ ${walletZIMBalance.toFixed(2)}`} />}
                </Grid>
            </Box>
            <ProfileSection />

            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
