import { useSelector, useDispatch } from 'react-redux';
import { LOGOUT } from 'store/actions';

// ==============================|| AUTH HOOKS ||============================== //

const useAuth = () => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

    if (isLoggedIn === null || isLoggedIn === false) {
        sessionStorage.clear();
        dispatch({
            type: LOGOUT
        });
        return { isLoggedIn: false };
    }

    return { isLoggedIn: true };
};

export default useAuth;
