import { useEffect } from 'react';

const useAutoLogout = (tokenExpirationTimestamp, onLogout) => {
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (Date.now() >= tokenExpirationTimestamp) {
        onLogout();
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 1000);

    return () => clearInterval(intervalId);
  }, [tokenExpirationTimestamp, onLogout]);
};

export default useAutoLogout;
