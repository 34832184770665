import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/es/storage/session';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import settingsReducer from './settingsReducer';
import walletReducer from './walletReducer';
import balanceTypeReducer from './balanceTypeReducer';
import popupReducer from './popupReducer';
import payNowCheckoutReducer from './payNowCheckoutReducer';
import authReducer from './authReducer';
import userProfileReducer from './userProfileReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    auth: persistReducer(
        {
            key: 'auth',
            storage: sessionStorage,
            keyPrefix: 'auth-'
        },
        authReducer
    ),
    customization: customizationReducer,
    snackbar: snackbarReducer,
    settings: persistReducer(
        {
            key: 'settings',
            storage: sessionStorage,
            keyPrefix: 'settings-'
        },
        settingsReducer
    ),
    balance: balanceTypeReducer,
    popup: popupReducer,
    wallet: persistReducer(
        {
            key: 'balance',
            storage: sessionStorage,
            keyPrefix: 'wallet-'
        },
        walletReducer
    ),
    payNowCheckout: persistReducer(
        {
            key: 'payNowCheckout',
            storage: sessionStorage,
            keyPrefix: 'paynow-'
        },
        payNowCheckoutReducer
    ),
    userProfile: persistReducer(
        {
            key: 'userProfile',
            storage: sessionStorage,
            keyPrefix: 'user-'
        },
        userProfileReducer
    )
});

export default reducer;
