import { lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom'; // Import Navigate

// routes
import ProtectedRoutes from './ProtectedRoutes';
import LoginRoutes from './LoginRoutes';
import Loadable from 'ui/ui-component/Loadable';
import OtherRoutes from './OtherRoutes';

const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const Error404 = Loadable(lazy(() => import('pages/maintenance/Error')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        { path: '/', element: <Navigate to="/login" replace /> },  // Redirect from "/" to "/login"
        LoginRoutes,
        ProtectedRoutes,
        OtherRoutes,
        { path: '*', element: <Error404 /> }
    ]);
}
