import { store } from 'store';
import { LOGOUT } from 'store/actions';

/* eslint-disable no-underscore-dangle */
const handleAxiosError = (error) => {
    if (error.response) {
        const { status } = error.response;

        if (error.response.data.message?.Status === 'Failed' || error.response.data.message?.Status === 'FailedRetryable') {
            // Check for TechnicalNarration first, then Narration
            return error.response.data.message?.TechnicalNarration || error.response.data.message?.Narration;
        }

        if (error.response.data.message?.Message) {
            return error.response.data.message.Message;
        }

        const sessionExpired = error.response.data?.session_expired;
        if (sessionExpired === 1 || sessionExpired === '1') {
            store.dispatch({ type: LOGOUT });
            sessionStorage.clear();
            return 'Your session has expired. Please log in again.';
        }

        const exceptionMessage = error.response.data.exception || '';
        const errorMessage = error.response.data.message || '';

        // Specific handling for UniqueValidationError by searching for 'frappe.exceptions.UniqueValidationError:'
        if (exceptionMessage.includes('frappe.exceptions.UniqueValidationError:')) {
            const duplicateEntryMessage = matchDuplicateEntryError(exceptionMessage);
            if (duplicateEntryMessage) {
                return duplicateEntryMessage;
            }
            return 'A record with the provided unique value already exists. Please check and try again.';
        }

        if (exceptionMessage.includes('frappe.exceptions.ValidationError')) {
            return 'Validation Error: ' + extractFrappeErrorMessage(exceptionMessage, 'frappe.exceptions.ValidationError');
        }

        if (exceptionMessage.includes('frappe.exceptions.PermissionError')) {
            store.dispatch({ type: LOGOUT });
            sessionStorage.clear();
            return 'PermissionError: Your session has expired. Please log in again.';
        }

        if (exceptionMessage.includes('frappe.exceptions.CSRFTokenError')) {
            return 'CSRFTokenError: Invalid Request';
        }

        if (exceptionMessage.includes('frappe.exceptions.AuthenticationError')) {
            return errorMessage;
        }

        if (errorMessage) {
            if (Array.isArray(errorMessage) && errorMessage.length === 2) {
                return errorMessage[1];
            }
            return errorMessage;
        }

        if (exceptionMessage) {
            return exceptionMessage;
        }

        if (error.response.data._error_message) {
            return error.response.data._error_message;
        }

        const err = error.toJSON();
        return err.message;
    }

    if (error.request) {
        return error.toString();
    }

    return `Error : ${error.message}`;
};

// Helper function to extract a more readable message from Frappe validation errors
function extractFrappeErrorMessage(exception, keyword) {
    const regex = new RegExp(`${keyword}:\\s*(.*)`);
    const match = exception.match(regex);
    return match ? match[1].trim() : 'An unknown validation error occurred.';
}

// Helper function to match and extract duplicate entry errors
function matchDuplicateEntryError(exception) {
    const regex = /Duplicate entry '(.+)' for key '(\w+)'/;
    const match = exception.match(regex);
    if (match && match.length >= 3) {
        return `The ${match[2]} '${match[1]}' is already in use.`;
    }
    return null;
}

export default handleAxiosError;
