import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { store } from 'store';
import { LOGOUT, SNACKBAR_OPEN } from 'store/actions';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
axios.defaults.withCredentials = true;

let logoutCallback = null;

const axiosPaynowAuthenticated = axios.create({
    withCredentials: true,
    timeout: 60000 // 60 seconds
});

const axiosDefault = axios.create({
    withCredentials: false
});

const axiosIntercepted = axios.create({
    withCredentials: true
});

const axiosAuthenticated = axios.create({
    withCredentials: true
});

// Add a request interceptor
axiosIntercepted.interceptors.request.use(
    async (config) => {
        // login before request is sent
        await axiosAuthenticated.post('/method/vps_retail.vps_api.login', { is_external: true });
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axiosIntercepted.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (error.response) {
            const { status } = error.response;
            if (status === 401 || status === 403) {
                const isLoggedIn = store.getState().auth.isLoggedIn;
                if (isLoggedIn) {
                    if (logoutCallback) {
                        logoutCallback();
                    }
                }
            }
        }

        return Promise.reject(error);
    }
);

axiosDefault.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const isLoggedIn = store.getState().auth.isLoggedIn;
            if (isLoggedIn) {
                if (logoutCallback) {
                    logoutCallback();
                }
            }
        }

        return Promise.reject(error);
    }
);

axiosAuthenticated.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const isLoggedIn = store.getState().auth.isLoggedIn;
            if (isLoggedIn) {
                if (logoutCallback) {
                    logoutCallback();
                }
            }
        }

        return Promise.reject(error);
    }
);

axiosPaynowAuthenticated.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        const { status } = error.response;
        if (status === 401 || status === 403) {
            const isLoggedIn = store.getState().auth.isLoggedIn;
            if (isLoggedIn) {
                if (logoutCallback) {
                    logoutCallback();
                }
            }
        }

        return Promise.reject(error);
    }
);

export { axiosDefault, axiosIntercepted, axiosAuthenticated, axiosPaynowAuthenticated };

export function setLogoutCallback(callback) {
    logoutCallback = callback;
}
