// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconPlayerRecord } from '@tabler/icons';

// constant
const icons = {
    IconPlayerRecord
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const countryServices = {
    id: 'country-services',
    type: 'group',
    title: <FormattedMessage id="country-services" />,
    children: [
        {
            id: 'zim-services',
            title: <FormattedMessage id="zim-services" />,
            type: 'item',
            url: '/services/zimbabwe',
            icon: icons.IconPlayerRecord,
            breadcrumbs: false
        },
        {
            id: 'sa-services',
            title: <FormattedMessage id="sa-services" />,
            type: 'item',
            url: '/services/south-africa',
            icon: icons.IconPlayerRecord,
            breadcrumbs: false
        }
    ]
};

export default countryServices;
