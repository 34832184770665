import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui/ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


// dashboard
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));

// Services Home Page
const ZimServicesHomePage =  Loadable(lazy(() => import('pages/ZimServices/zim-services-home')));
const SAServicesHomePage = Loadable(lazy(() => import('pages/SAServices/sa-services-home')));


// tables - transactions history
const AirtimeTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/AirtimeTable')));
const SAAirtimeTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/SAAirtimeTable')));
const USDAirtimeTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/USDAirtimeTable')));
const USDBundlesTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/USDBundlesTable')));
const BillerPaymentsTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/BillerPaymentsTable')));
const ZesaRechargeTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/ZesaRechargeTable')));
const EconetBundleTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/EconetBundleTable')));
const HRBundleTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/HRBundleTable')));
const SimpleVouchersTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/SimpleVouchersTable')));
const OTTVouchersTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/OTTVouchersTable')));
const SCODEVouchersTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/SCODEVouchersTable')));
const SimpleVouchersTransactionTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/VouchersTransactionTable')));
const SimplexPaymentTransactions = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/SimplexPaymentTransactions')));
const MultichoicePaymentTransactions = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/MultichoiceTable')));
const EskomElectricityTransactions = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/EskomElectricityTransactions')));
const NyaradzoTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/NyaradzoTable')));
const TeloneTransactionTable = Loadable(lazy(() => import('pages/TransactionsHistory/transaction-tables/TeloneTransactionTable')));


// purchase simple voucher
const PurchaseSimpleVoucherPage = Loadable(lazy(() => import('pages/transactions/purchase-simple-voucher')));

// top up wallet options home page
const TopUpWalletOptions = Loadable(lazy(() => import('pages/TopUpWallet/top-up-wallet-options-page')));

// TOP UP WALLET OPTIONS DETAILS PAGES
const TopUpSimpleWalletPage = Loadable(lazy(() => import('pages/TopUpWallet/top-up-wallet-options/top-up-wallet')));
const TopUpSimpleWalletOTTPage = Loadable(lazy(() => import('pages/TopUpWallet/top-up-wallet-options/top-up-wallet-ott')));
const PurchaseSCODEVoucherPage = Loadable(lazy(() => import('pages/TopUpWallet/top-up-wallet-options/generate-scode-voucher')));
const RedeemSimpleVoucher = Loadable(lazy(() => import('pages/TopUpWallet/top-up-wallet-options/top-up-wallet-simplex-voucher')));
const RedeemCollectVoucher = Loadable(lazy(() => import('pages/TopUpWallet/top-up-wallet-options/top-up-wallet-instant-money')));

// SA Services
const SAAirtime = Loadable(lazy(() => import('pages/SAServices/sa-services/SAAirtime')));
const SABundles = Loadable(lazy(() => import('pages/SAServices/sa-services/SABundles')));
const PayDSTVPage = Loadable(lazy(() => import('pages/SAServices/sa-services/PayDSTV')));
const RechargeEskomElectricity = Loadable(lazy(() => import('pages/SAServices/sa-services/RechargeEskom')));
const PrepaidWater = Loadable(lazy(() => import('pages/SAServices/sa-services/PrepaidWater')));
const PurchaseOTTVoucherPage = Loadable(lazy(() => import('pages/SAServices/sa-services/OTTVoucher')));

// ZIM Services

// Hot Recharge
const RechargeAirtimePage = Loadable(lazy(() => import('pages/ZimServices/zim-services/hot-recharge/RechargeAirtime')));
const RechargeBundlesPage = Loadable(lazy(() => import('pages/ZimServices/zim-services/hot-recharge/RechargeBundles')));
const RechargeUtilityPage = Loadable(lazy(() => import('pages/ZimServices/zim-services/hot-recharge/RechargeUtility')));
const RechargeTelonePage = Loadable(lazy(() => import('pages/ZimServices/zim-services/hot-recharge/RechargeTelone'))); 

// Econet USD
const EconetAirtimeUSDPage = Loadable(lazy(() => import('pages/ZimServices/zim-services/econet/EconetAirtimeUSD')));
const USDEconetBundlesPage = Loadable(lazy(() => import('pages/ZimServices/zim-services/econet/USDEconetBundles')));

// PAYNOW IMPLEMENTATION
const BillerFormWizard = Loadable(lazy(() => import('pages/ZimServices/zim-services/paynow/BillerFormWizard')));
const DepartmentDetails =  Loadable(lazy(() => import('pages/ZimServices/zim-services-home/department-details')));

// ==============================|| MAIN ROUTING ||============================== //

const ProtectedRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: '/top-up-wallet-options',
            element: <TopUpWalletOptions />
        },
        {
            path: '/services/zimbabwe',
            element: <ZimServicesHomePage />
        },
        {
            path: '/services/south-africa',
            element: <SAServicesHomePage />
        },
        {
            path: '/services/zimbabwe/:department',
            element: <DepartmentDetails />
        },
        {
            path: '/buy-simple-voucher',
            element: <PurchaseSimpleVoucherPage />
        },
        {
            path: '/buy-ott-voucher',
            element: <PurchaseOTTVoucherPage />
        },
        {
            path: '/create-scode-payment',
            element: <PurchaseSCODEVoucherPage />
        },
        {
            path: '/top-up-wallet',
            element: <TopUpSimpleWalletPage />
        },
        {
            path: '/top-up-wallet-ott',
            element: <TopUpSimpleWalletOTTPage />
        },
        {
            path: '/top-up-wallet-instant-money',
            element: <RedeemCollectVoucher />
        },
        {
            path: '/top-up-wallet-simple-voucher',
            element: <RedeemSimpleVoucher />
        },
        {
            path: '/recharge/pinless',
            element: <SAAirtime />
        },
        {
            path: '/recharge/pinless/bundles',
            element: <SABundles />
        },
        {
            path: '/recharge/airtime',
            element: <RechargeAirtimePage />
        },
        {
            path: '/recharge/data-bundles',
            element: <RechargeBundlesPage />
        },
        {
            path: '/recharge/airtime/econet-usd',
            element: <EconetAirtimeUSDPage />
        },
        {
            path: '/recharge/econet/usd-bundles',
            element: <USDEconetBundlesPage />
        },
        {
            path: '/recharge/utility-bills',
            element: <RechargeUtilityPage />
        },
        {
            path: '/recharge/telone',
            element: <RechargeTelonePage />
        },
        {
            path: '/recharge/eskom-electricity',
            element: <RechargeEskomElectricity />
        },
        {
            path: '/pay/prepaid-water',
            element: <PrepaidWater />
        },
        {
            path: '/recharge/multichoice',
            element: <PayDSTVPage />
        },
        {
            path: '/transactions/airtime',
            element: <AirtimeTable />
        },
        {
            path: '/transactions/pinless-airtime',
            element: <SAAirtimeTable />
        },

        {
            path: '/transactions/bundles',
            element: <HRBundleTable />
        },
        {
            path: '/transactions/zesa-tokens',
            element: <ZesaRechargeTable />
        },
        {
            path: '/transactions/econet-bundles',
            element: <EconetBundleTable />
        },
        {
            path: '/transactions/simple-vouchers',
            element: <SimpleVouchersTransactionTable />
        },
        {
            path: '/transactions/payments',
            element: <SimplexPaymentTransactions />
        },
        {
            path: '/transactions/multichoice',
            element: <MultichoicePaymentTransactions />
        },
        {
            path: '/transactions/eskom-electricity',
            element: <EskomElectricityTransactions />
        },
        {
            path: '/transactions/nyaradzo',
            element: <NyaradzoTable />
        },
        {
            path: '/transactions/telone',
            element: <TeloneTransactionTable />
        },
        {
            path: '/transactions/usd-airtime',
            element: <USDAirtimeTable />
        },
        {
            path: '/transactions/usd-bundles',
            element: <USDBundlesTable />
        },
        {
            path: '/transactions/billers',
            element: <BillerPaymentsTable />
        },
        {
            path: '/simple-vouchers',
            element: <SimpleVouchersTable />
        },
        {
            path: '/ott-vouchers',
            element: <OTTVouchersTable />
        },
        {
            path: '/scode-vouchers',
            element: <SCODEVouchersTable />
        },
        {
            path: '/pay-now/pay/:billerProductName',
            element: <BillerFormWizard />
        }
    ]
};

export default ProtectedRoutes;
