// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconHome, IconChartArcs3 } from '@tabler/icons';

// constant
const icons = {
    IconHome,
    IconChartArcs3
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'default',
            title: <FormattedMessage id="default" />,
            type: 'item',
            url: '/dashboard/',
            icon: icons.IconHome,
            breadcrumbs: true
        }
    ]
};

export default dashboard;
