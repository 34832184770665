// action - state management
import * as actionTypes from './actions';

const initialCustomerData = {
    memberNumber: '',
    memberName: '',
    memberAddress: '',
    phoneNumber: '',
    amount: 0,
    productQuantity: 1,
    total: null,
    selectedProduct: null,
    totalInRands: null,
    productUnitPrice: null,
    currency: null,
    postPurchaseInstructions: null
};

const initialState = {
    productData: {},
    customerData: initialCustomerData,
    billerData: {}
};

// ==============================|| PAYNOW CHECKOUT REDUCER ||============================== //

const payNowCheckoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_CHECKOUT_PRODUCT:
            return {
                ...state,
                productData: action.productData
            };
        case actionTypes.UPDATE_CUSTOMER_DATA:
            return {
                ...state,
                customerData: {
                    ...state.customerData,
                    ...action.payload 
                }
            };
        case actionTypes.LOAD_CHECKOUT_BILLER:
            return {
                ...state,
                billerData: action.billerData ? action.billerData : initialState.billerData
            };
        case actionTypes.CLEAR_CHECKOUT_DATA:
            return {
                ...state,
                productData: initialState.productData,
                customerData: initialCustomerData,
                billerData: initialState.billerData
            };

        case actionTypes.RESET_CUSTOMER_DATA:
            return {
                ...state,
                customerData: initialCustomerData
            };
        default:
            return state;
    }
};

export default payNowCheckoutReducer;
